/* eslint-disable @typescript-eslint/no-namespace,no-inner-declarations */
import { BvToastOptions } from 'bootstrap-vue/src/components/toast'

export enum ServerType {
  None = 0,
  Local = 2,
  S3File = 1001
}

export const AWSConstant = {
  AWS_CREDENTIAL_EXPIRE_LIMIT_SECONDS: 120,

  AWS_UPLOAD_PART_SIZE: 5 * 1024 * 1024,
  AWS_UPLOAD_QUEUE_SIZE: 4,

  DEFAULT_TAGS: process.env.NODE_ENV === 'production' ? [{
    Key: 'Company',
    Value: 'LITEVIEW'
  }, {
    Key: 'Service',
    Value: 'liteview'
  }] : [{
    Key: 'Company',
    Value: 'LITEVIEW'
  }, {
    Key: 'Service',
    Value: 'liteview'
  }, {
    Key: 'Development',
    Value: 'true'
  }]
}

export enum FileType {
  pdf = 0,
  img = 10,
  music = 20,
  movie = 30,
  other = 90
}

export enum ResourceMode {
  VideoOnly = 1,
  RightResource = 2,
  LeftResource = 3
}

export const StoreKey = {
  VIMEO_HOW_TO: 'vimeo_how_to',
  UUID: 'u_u',
  POLL_UID: 'poll_{uid}_{uuid}',
  BOARD_POLL_TEMPLATE: 'board_poll_template2',
  AWS_IDENTITY: 'awsid_',
  SERVICE_REGISTERED: '{service}_reg',
  SERVICE_CONTENT_LIST_POPUP: '{service}_{email}_clp',
  SERVICE_CONTENT_LIST_POPUP_NO: '{service}_{email}_clp_no',
  DEVICE_TOKEN: 'd_t',
  FORUM_TMP: 'forum_{uid}_{uuid}',
  VERTICAL_TEXT_GUIDE: 'wf_ft_vertical',
  START_MESSAGE: 'sm_{uid}_{uuid}'
}

export interface ProgressData<T> {
  progress: number;
  end: boolean;
  response?: T;
}

enum ContentType {
  None = 0,
  Video = 1,
  Online = 2,
  Offline = 3,
  VimeoLive = 4,
  Radio = 5,
  Blog = 6,
  PDF = 7,
  OnlineSchedule = 8,
  LiveCommerceZoom = 9,
  LiveCommerceVimeo = 10,
  Signing = 11,
  LiveCommerceYoutube = 12,
  YoutubeLive = 13,
  OnlineScheduleVimeo = 14,
  Schedule = 15,
  ByteLive = 16,
  OnlineScheduleByteLive = 17,
  LiveCommerceByteLive = 18
}

namespace ContentType {
  export function isPreview(type: ContentType): boolean {
    switch (type) {
      case ContentType.Video:
      case ContentType.Radio:
      case ContentType.PDF:
        return true
    }
    return false
  }

  export function isZoom(type: ContentType): boolean {
    switch (type) {
      case ContentType.Online:
      case ContentType.OnlineSchedule:
      case ContentType.LiveCommerceZoom:
      case ContentType.Signing:
        return true
    }
    return false
  }

  export function isVimeoLive(type: ContentType): boolean {
    switch (type) {
      case ContentType.VimeoLive:
      case ContentType.LiveCommerceVimeo:
      case ContentType.OnlineScheduleVimeo:
        return true
    }
    return false
  }

  export function isYoutubeLive(type: ContentType): boolean {
    switch (type) {
      case ContentType.LiveCommerceYoutube:
      case ContentType.YoutubeLive:
        return true
    }
    return false
  }

  export function isByteLive(type: ContentType): boolean {
    switch (type) {
      case ContentType.ByteLive:
      case ContentType.OnlineScheduleByteLive:
      case ContentType.LiveCommerceByteLive:
        return true
    }
    return false
  }

  export function isLive(type: ContentType): boolean {
    switch (type) {
      case ContentType.Online:
      case ContentType.VimeoLive:
      case ContentType.OnlineSchedule:
      case ContentType.LiveCommerceZoom:
      case ContentType.LiveCommerceVimeo:
      case ContentType.Signing:
      case ContentType.LiveCommerceYoutube:
      case ContentType.YoutubeLive:
      case ContentType.OnlineScheduleVimeo:
      case ContentType.ByteLive:
      case ContentType.OnlineScheduleByteLive:
      case ContentType.LiveCommerceByteLive:
        return true
    }
    return false
  }

  export function isLiveWithoutSchedule(type: ContentType): boolean {
    switch (type) {
      case ContentType.Online:
      case ContentType.VimeoLive:
      case ContentType.LiveCommerceZoom:
      case ContentType.LiveCommerceVimeo:
      case ContentType.LiveCommerceYoutube:
      case ContentType.YoutubeLive:
      case ContentType.ByteLive:
      case ContentType.LiveCommerceByteLive:
        return true
    }
    return false
  }

  export function isEvent(type: ContentType): boolean {
    switch (type) {
      case ContentType.Online:
      case ContentType.Offline:
      case ContentType.VimeoLive:
      case ContentType.Blog:
      case ContentType.OnlineSchedule:
      case ContentType.LiveCommerceZoom:
      case ContentType.LiveCommerceVimeo:
      case ContentType.Signing:
      case ContentType.LiveCommerceYoutube:
      case ContentType.YoutubeLive:
      case ContentType.OnlineScheduleVimeo:
      case ContentType.Schedule:
      case ContentType.ByteLive:
      case ContentType.OnlineScheduleByteLive:
      case ContentType.LiveCommerceByteLive:
        return true
    }
    return false
  }

  export function isVideo(type: ContentType): boolean {
    switch (type) {
      case ContentType.Video:
      case ContentType.Radio:
        return true
    }
    return false
  }

  export function hasPaymentDescription(type: ContentType): boolean {
    switch (type) {
      case ContentType.Offline:
      case ContentType.Blog:
        return true
    }
    return false
  }

  export function isLiveCommerce(type: ContentType): boolean {
    switch (type) {
      case ContentType.LiveCommerceZoom:
      case ContentType.LiveCommerceVimeo:
      case ContentType.LiveCommerceYoutube:
      case ContentType.LiveCommerceByteLive:
        return true
    }
    return false
  }

  export function isScheduleEvent(type: ContentType): boolean {
    switch (type) {
      case ContentType.OnlineSchedule:
      case ContentType.Signing:
      case ContentType.OnlineScheduleVimeo:
      case ContentType.Schedule:
      case ContentType.OnlineScheduleByteLive:
        return true
    }
    return false
  }

  export function isOnlineSchedule(type: ContentType): boolean {
    switch (type) {
      case ContentType.OnlineSchedule:
      case ContentType.OnlineScheduleVimeo:
      case ContentType.Schedule:
      case ContentType.OnlineScheduleByteLive:
        return true
    }
    return false
  }
}

export {
  ContentType
}

export const corsURL = (url: string): string => {
  if (url.startsWith('data:')) {
    return url
  }
  const uri = new URL(url)
  if (uri.hostname === 'localhost' || uri.hostname === '127.0.0.1') {
    return url
  }
  const current = new URL(window.location.href)
  if ((!uri.protocol || uri.protocol === current.protocol) && uri.host === current.host) {
    return url
  }
  return new URL('/corsfile', process.env.VUE_APP_PLAYER_API_HOST).toString() + '?url=' + encodeURIComponent(url)
}

const removeSubdomainFrom = (fullUrl: string) => {
  // configure host strings
  const regex = {
    protocol: new RegExp(/http(s)*:\/\//), // gets the http:// OR https:// from url string
    subdomain: new RegExp(/^[^.]*\.(?=\w+\.\w+$)/) // gets the http(s)://subdomain portion from url string
  }
  let newUrl = fullUrl
  const protocol = regex.protocol.exec(fullUrl) // save protocol from provided Url so we can reapply it to the non-subdomain
  if (protocol && protocol.length) {
    // if https://subdomain exists, just remove the subdomain from it
    newUrl = fullUrl.replace(regex.subdomain, protocol[0])
  }
  return newUrl
}

export const isAPIHost = (url: string): boolean => {
  const current = new URL(process.env.VUE_APP_PLAYER_API_HOST ?? '')
  const uri = new URL(url)
  return (!uri.protocol || uri.protocol === current.protocol) &&
    (removeSubdomainFrom(uri.toString()) === removeSubdomainFrom(current.toString()))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const imageViewerToolbarOption = (thirdOAuthType?: number | null): any | undefined => {
  if (thirdOAuthType === 10001) {
    return {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 1,
      play: 0,
      next: 1,
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0
    }
  }
  return {
    zoomIn: 4,
    zoomOut: 4,
    oneToOne: 4,
    reset: 4,
    prev: 1,
    play: 4,
    next: 1,
    rotateLeft: 4,
    rotateRight: 4,
    flipHorizontal: 4,
    flipVertical: 4
  }
}

export const defaultToastOption: BvToastOptions = {
  solid: true,
  toaster: 'b-toaster-bottom-right',
  appendToast: true,
  noCloseButton: true,
  headerClass: 'd-none',
  bodyClass: 'toaster-default',
  toastClass: 'toaster-show'
}

export const DEFAULT_SEARCH_DEBOUNCE_TIME = 500

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

enum UserCustomFormType {
  Text = 1,
  Radio = 2,
  Checkbox = 3,
  Date = 4,
  BirthDate = 5,
  Gender = 6,
  Address = 7,
  Phone = 8,
  Lastname = 9,
  Firstname = 10,
  LastnameFuri = 11,
  FirstnameFuri = 12,
  Select = 13,
  JPState = 14,
  LongText = 15,
  JsonAddress = 16,
  Slider = 17,
  Number = 18,
  Price = 19,
  Email = 20,
  Fullname = 21,
  File = 101,
  ImageFile = 102,
  Working = 201,
  Title = 1001,
  Body = 1002,
  MustRead = 1003,
  WorkflowParams = 2001
}

namespace UserCustomFormType {
  export function isText(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Text:
      case UserCustomFormType.Lastname:
      case UserCustomFormType.Firstname:
      case UserCustomFormType.LastnameFuri:
      case UserCustomFormType.FirstnameFuri:
      case UserCustomFormType.LongText:
      case UserCustomFormType.Fullname:
        return true
      default:
        return false
    }
  }

  export function isShortText(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Text:
      case UserCustomFormType.Lastname:
      case UserCustomFormType.Firstname:
      case UserCustomFormType.LastnameFuri:
      case UserCustomFormType.FirstnameFuri:
      case UserCustomFormType.Fullname:
        return true
      default:
        return false
    }
  }

  export function isRadio(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Radio:
      case UserCustomFormType.Select:
      case UserCustomFormType.JPState:
      case UserCustomFormType.Working:
      case UserCustomFormType.Gender:
        return true
      default:
        return false
    }
  }

  export function isSelect(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Select:
      case UserCustomFormType.JPState:
      case UserCustomFormType.Working:
        return true
      default:
        return false
    }
  }

  export function isAddress(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Address:
      case UserCustomFormType.JsonAddress:
        return true
      default:
        return false
    }
  }

  export function isDate(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.Date:
      case UserCustomFormType.BirthDate:
        return true
      default:
        return false
    }
  }

  export function isFile(type?: UserCustomFormType | null) {
    switch (type) {
      case UserCustomFormType.File:
      case UserCustomFormType.ImageFile:
        return true
      default:
        return false
    }
  }
}

export {
  UserCustomFormType
}
