import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Proto } from '@/player/model/proto'
import { ErrorCode, MessageError } from '@/player/model/error'
import * as $protobuf from 'protobufjs'
import { util } from 'protobufjs'
import app from '@/main'
import { State } from '@/store'
import { SecurityService } from '@/player/service/security.service'
import IReturnData = Proto.IReturnData
import ResultCode = Proto.ResultCode

// const isProd = process.env.NODE_ENV === 'production'
export const API_HOST = process.env.VUE_APP_PLAYER_API_HOST

export class PlayerJsonReturnData<T> {
  /** ReturnData code */
  code?: (Proto.ResultCode | null)

  /** ReturnData data */
  data?: T | null

  /** ReturnData error */
  error?: (Proto.ReturnData.IReturnError | null)
}

class PlayerHttp {
  private static _instance: PlayerHttp | null = null

  static instance(): PlayerHttp {
    if (PlayerHttp._instance === null) {
      PlayerHttp._instance = new PlayerHttp()
    }
    return PlayerHttp._instance
  }

  private constructor() {

  }

  // axios.defaults.withCredentials = true
  httpAxios = axios.create({
    withCredentials: true,
    headers: {
      common: {
        'X-V-API': process.env.VUE_APP_PLAYER_API_VERSION,
        'X-E-API': process.env.VUE_APP_PLAYER_API_TOKEN,
        'X-TZ': (new Date().getTimezoneOffset() * 60).toString(10)
      }
    },
    transformRequest: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any, headers?: any): any => {
        if (data && headers['Content-Type'] === 'application/x-protobuf') {
          if (data instanceof $protobuf.Writer) {
            return (data as $protobuf.Writer).finish()
          }
        }
        return data
      }
    ],
    transformResponse: [
      (data, headers) => {
        if (data && headers['content-type'] === 'application/x-protobuf') {
          const arr = new Uint8Array(data)
          return Proto.ReturnData.decode(arr, arr.length)
        }
        return data
      }
    ]
  })

  requestURL<T>(url: string, request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    request.url = url
    return this.httpAxios(request).catch(e => {
      if (e.isAxiosError && e.response && (e.response.status === 401 || e.response.status === 403)) {
        return Promise.resolve(e.response)
      }
      if (e.isAxiosError && !e.response) {
        return Promise.reject(MessageError.from(ErrorCode.NoInternet, e))
      }
      return Promise.reject(e)
    })
  }

  requestAPIURL(endpoint: string): string {
    let url: string
    if (app && app.$store && (app.$store.state as State).meta.isCustom) {
      url = '/api/' + endpoint
    } else {
      url = API_HOST + endpoint
    }
    return url
  }

  requestAPI<T>(endpoint: string, request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return SecurityService.getInstance().checkSecurityIP()
      .then(res => {
        if (res) {
          return this.requestURL(this.requestAPIURL(endpoint), request)
        }
        throw MessageError.from(ErrorCode.ServerSecurityIP)
      })
  }

  requestAPIJson<T extends PlayerJsonReturnData<unknown>>(endpoint: string, request: AxiosRequestConfig): Promise<T> {
    const header = request.headers ?? {}
    if (!header.Accept) {
      header.Accept = 'application/json'
    }
    if (request.data && !header['Content-Type']) {
      header['Content-Type'] = 'application/json'
    }
    request.headers = header
    request.responseType = 'json'
    return this.requestAPI<T>(endpoint, request)
      .then(res => PlayerHttp.defaultHandleAPIResponse(res))
  }

  requestAPIProto<T extends IReturnData>(endpoint: string, request: AxiosRequestConfig): Promise<T> {
    const header = request.headers ?? {}
    if (!header.Accept) {
      header.Accept = 'application/x-protobuf'
    }
    if (request.data && !header['Content-Type']) {
      header['Content-Type'] = 'application/x-protobuf'
    }
    request.headers = header
    request.responseType = 'arraybuffer'
    return this.requestAPI<T>(endpoint, request)
      .then(res => PlayerHttp.defaultHandleAPIResponse(res))
  }

  static defaultHandleAPIResponse<T extends (IReturnData | PlayerJsonReturnData<unknown>)>(res: AxiosResponse<T>): Promise<T> {
    let loginError = false
    if (res.status === 200) {
      if (res.data?.code === ResultCode.Login) {
        loginError = true
      }
    } else if (res.status === 401 || res.status === 403) {
      loginError = true
    }
    if (loginError) {
      return Promise.reject(MessageError.from(ErrorCode.ServerLoginNeed))
    } else if (res.status !== 200) {
      return Promise.reject(PlayerHttp._makeError(res))
    } else if (res.data?.code === ResultCode.Error) {
      return Promise.reject(PlayerHttp.makeErrorResponse(res.data))
    }
    return Promise.resolve(res.data)
  }

  private static _makeError<T>(res: AxiosResponse<T>) {
    return MessageError.from(ErrorCode.ServerInternal, new Error(res.status + ' : ' + res.statusText))
  }

  public static makeErrorResponse<T extends (IReturnData | PlayerJsonReturnData<unknown>)>(res?: T) {
    if (res && res.code === ResultCode.Error) {
      if (res.error?.code === Proto.ErrorCode.SERVER_MESSAGE && res.data) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window.Vue as any)?.$log?.debug(res)
        let msg = ''
        if (res.data) {
          if (util.isString(res.data)) {
            msg = res.data as string
          } else {
            const arr = res.data as Uint8Array
            msg = util.utf8.read(arr, 0, arr.length)
          }
        }
        if (!msg) {
          msg = res.error?.message || ''
        }
        return new MessageError(ErrorCode.ServerMessage, msg)
      } else if (res.error?.code === Proto.ErrorCode.USER_RESIGN) {
        return MessageError.from(ErrorCode.UserResign, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.JOIN_MAX_USER) {
        return MessageError.from(ErrorCode.JoinMaxUser, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.NO_CONTENT) {
        return MessageError.from(ErrorCode.NoContent, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.NO_CONTENT_PUBLIC) {
        const err = MessageError.from(ErrorCode.NoContentPublic, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        const dateString = res?.error?.message ?? ''
        err.messageBlock = () => {
          if (dateString) {
            const date = moment(parseInt(dateString, 10))
            if (date.isBefore(moment())) {
              return app.$tc('player.error.code.30002_closed')
            }
            return app.$tc('player.error.code.' + err.code, undefined, { date: date.format('LLL') })
          }
          return app.$tc('player.error.code.30002_no')
        }
        return err
      } else if (res.error?.code === Proto.ErrorCode.NO_ZOOM) {
        return MessageError.from(ErrorCode.NoZoom, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.LIVE_USER_LIMIT) {
        return MessageError.from(ErrorCode.LiveUserLimit, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.IS_OWNER) {
        return MessageError.from(ErrorCode.IsOwner, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.IS_USER) {
        return MessageError.from(ErrorCode.IsUser, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.TRAFFIC_EXCEED) {
        return MessageError.from(ErrorCode.ServerTrafficExceed, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.STORAGE_EXCEED) {
        return MessageError.from(ErrorCode.ServerStorageExceed, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.DUPLICATE_PLAN) {
        return MessageError.from(ErrorCode.DuplicatedPlan, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PLAN_DEPS) {
        const error = MessageError.from(ErrorCode.PlanDependency)
        error.messageBlock = () => {
          return app.$i18n?.tc(`player.error.code.${ErrorCode.PlanDependency}`, undefined, { name: res.error?.message ?? '' }) ?? ''
        }
        return error
      } else if (res.error?.code === Proto.ErrorCode.PLAN_USER_EXCEED) {
        return MessageError.from(ErrorCode.PlanUserLimitExceed, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PLAN_SIGNUP_BLOCKED) {
        return MessageError.from(ErrorCode.PlanSignupBlocked, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.USER_BLOCK) {
        return MessageError.from(ErrorCode.UserBlock, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.LP_STOP) {
        return MessageError.from(ErrorCode.LPStop, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.RECAPTCHA) {
        return MessageError.from(ErrorCode.RecaptchaError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.POLL_DUPLICATE) {
        return MessageError.from(ErrorCode.PollDuplicate, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.ACCOUNT_BLOCK) {
        return MessageError.from(ErrorCode.ServerLoginBlock, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.BOARD_GROUP_JOIN_DUPLICATE) {
        return MessageError.from(ErrorCode.GroupChatJoinedDuplicated, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PAYMENT_REFUND_ERROR) {
        return MessageError.from(ErrorCode.PaymentRefundFail, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PAYMENT_REFUND_DELIVERED_ERROR) {
        return MessageError.from(ErrorCode.PaymentRefundDeliveredFail, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PAYMENT_REFUND_FIXED_ERROR) {
        return MessageError.from(ErrorCode.PaymentRefundFixedError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.PAYMENT_CANCEL_POLICY) {
        const error = MessageError.from(ErrorCode.PaymentCancelPolicyError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        error.messageBlock = () => {
          return res.error?.message || ''
        }
        return error
      } else if (res.error?.code === Proto.ErrorCode.BLOCK_ADMIN) {
        return MessageError.from(ErrorCode.BlockAdmin, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.MULTIPLE_ACCOUNT) {
        return MessageError.from(ErrorCode.MultipleAccount, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.BOARD_GROUP_EXCLUSIVE_NOT_USER) {
        return MessageError.from(ErrorCode.GroupChatNotExclusiveUser, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.NO_THIRD_USER) {
        const error = MessageError.from(ErrorCode.NoThirdOAuthUser)
        error.messageBlock = () => {
          return app.$i18n?.tc(`player.error.code.${ErrorCode.NoThirdOAuthUser}`, undefined, { name: res.error?.message ?? '' }) ?? ''
        }
        return error
      } else if (res.error?.code === Proto.ErrorCode.DEVICE_COUNT) {
        const err = MessageError.from(ErrorCode.MaxDeviceError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        const data = res?.error?.message
        err.messageBlock = () => {
          let msg = app.$tc('player.error.code.' + err.code)
          if (data) {
            msg += '\n\n' + data.split(',').map(d => `・ ${d}`).join('\n')
          }
          return msg
        }
        return err
      } else if (res.error?.code === Proto.ErrorCode.SCHEDULE_MAX_USER) {
        return MessageError.from(ErrorCode.ScheduleMaxUserError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.SCHEDULE_DUPLICATE) {
        return MessageError.from(ErrorCode.ScheduleDuplicatedError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.SECURITY_IP) {
        return MessageError.from(ErrorCode.ServerSecurityIP, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.LOGIN_DOMAIN) {
        return MessageError.from(ErrorCode.ServerLoginDomain, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.SCHEDULE_STAFF) {
        return MessageError.from(ErrorCode.TicketNoStaffError, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.WORKFLOW_NO_ACCESS) {
        const err = MessageError.from(ErrorCode.NoWorkflowAccess, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        if (res.data && res.data instanceof Uint8Array && (res.data as Uint8Array).length) {
          const arr = res.data as Uint8Array
          const workflow = Proto.WorkflowBuilder.decode(arr, arr.length)
          err.meta = {
            data: workflow
          }
        }
        return err
      } else if (res.error?.code === Proto.ErrorCode.WORKFLOW_INPUT_DUPLICATE) {
        const err = MessageError.from(ErrorCode.WorkflowDuplicate, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        if (res.data && res.data instanceof Uint8Array && (res.data as Uint8Array).length) {
          const arr = res.data as Uint8Array
          const workflow = Proto.WorkflowBuilder.decode(arr, arr.length)
          err.meta = {
            data: workflow
          }
          err.messageBlock = () => {
            return app.$tc('player.error.code.' + err.code, undefined, { value: workflow.title })
          }
        }
        return err
      } else if (res.error?.code === Proto.ErrorCode.BOARD_GROUP_NOT_JOINED) {
        return MessageError.from(ErrorCode.GroupChatUserNotJoined, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.WORKFLOW_FILE_DUPLICATE) {
        return MessageError.from(ErrorCode.WorkflowFileDuplicate, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.WORK_NO_STAFF) {
        return MessageError.from(ErrorCode.WorkNoStaff, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.POINT_SHORTAGE) {
        return MessageError.from(ErrorCode.PointShortage, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.POINT_ENABLE) {
        return MessageError.from(ErrorCode.PointEnable, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.POINT_MAX_CHARGE) {
        return MessageError.from(ErrorCode.PointMaxCharge, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.TVOD_ENABLE) {
        return MessageError.from(ErrorCode.TVODEnable, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.TVOD_DUPLICATE) {
        return MessageError.from(ErrorCode.TVODDuplicate, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.TVOD_INAPP_UNAVAILABLE) {
        return MessageError.from(ErrorCode.TVODInappUnavailable, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
      } else if (res.error?.code === Proto.ErrorCode.MAINTENANCE) {
        // app.$router?.replace({
        //   path: '/player/maintenance'
        // })
        const err = MessageError.from(ErrorCode.Maintenance, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`))
        err.meta = { data: res.error?.message ?? '' }
        return err
      }
    }
    return MessageError.from(ErrorCode.ServerInternal, new Error(`code: ${res?.error?.code}, msg: ${res?.error?.message}`), res?.error?.code)
  }

  public static randomUUID() {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = [] as string[]
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((parseInt(s[19], 10) & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    return s.join('')
  }
}

export default PlayerHttp
